import React, { useContext, useMemo } from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import Input from 'components/Input';
import Context from '../context';

const PeopleControls = () => {
  const {
    search,
    setSearch,
    positionFilter,
    setPositionFilter,
    positionOptions,
    exportTable
  } = useContext(Context);
  const layout = useLayout();

  const positionFilterDropdownText = useMemo(() => {
    if (positionFilter.length === positionOptions.length) return 'All';
    if (positionFilter.length === 1) {
      return (positionOptions?.find(item => item.value === positionFilter[0])?.text ||
        positionFilter[0]) as string;
    }
    return `${positionFilter.length} positions`;
  }, [positionFilter, positionOptions]);

  return (
    <StyledPeopleControls className="people-controls">
      {layout === 'mobile' ? (
        <>
          <div className="people-controls__header">
            <h4 className="people__title">People</h4>
            <Dropdown
              className="people-controls__dropdown"
              value={positionFilter}
              onChange={setPositionFilter}
              options={positionOptions}
              label="Position:"
              text={positionFilterDropdownText}
              buttonLike
              multiple
              optionAll
            />
            <Button onClick={exportTable} className="people-controls__btn" secondary>
              <Icon name="download" size={12} />
            </Button>
          </div>
          <div className="people__description">All the people that involved in your projects.</div>
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            // clear={() => setSearch('')}
            icon="search"
            iconPosition="left"
            placeholder="Search"
            className="people-controls__input"
            data-cy="input_search"
            blurOnSubmit
          />
        </>
      ) : (
          <>
            <h4 className="people__title">People</h4>
            <div className="people__description">All the people that involved in your projects.</div>
            <div className="people-controls__filter">
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                // clear={() => setSearch('')}
                icon="search"
                iconPosition="left"
                placeholder="Search"
                className="people-controls__input"
                data-cy="input_search"
                blurOnSubmit
              />
              <StyledDropdown
                className="people-controls__dropdown"
                value={positionFilter}
                onChange={setPositionFilter}
                options={positionOptions}
                label="Position:"
                text={positionFilterDropdownText}
                buttonLike
                multiple
                optionAll
              />
              <Button onClick={exportTable} className="people-controls__btn" secondary>
                <Icon name="download" size={12} />
              Export as .csv
            </Button>
            </div>
          </>
        )}
    </StyledPeopleControls>
  );
};

export default React.memo(PeopleControls);

const StyledPeopleControls = styled.div`
  display: flex;
  flex-flow: column;

  .people-controls {
    &__input {
      margin: 0px auto 0px 0px;
      width: 300px;
    }
    &__btn {
      margin: 0 0 0 20px;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 4px;
      }
    }
    &__filter {
      margin: 30px 0 12px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    .people-controls {
      margin: 0 0 20px;
      flex-wrap: wrap;
      &__header {
        display: flex;
        width: 100%;
      }
      &__input {
        width: 100%;
      }
      &__btn {
        margin-left: 12px;
        .icon {
          margin-right: 0;
        }
      }
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  .dropdown-options {
    width: 154px;
    right: 0;
  }
`;
