import React, { useContext } from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import MainLayout from 'components/MainLayout';
import Modal from 'components/Modal';
import SideMenu from 'components/SideMenu';
import PeopleControls from './components/PeopleControls';
import PeopleList from './components/PeopleList';
import SendMessage from './components/SendMessage';
import RecentProjects from './components/RecentProjects';
import TableSkeleton from './components/TableSkeleton';
import Context, { ContextProvider } from './context';

const PeoplePage = () => {
  const {
    isLoading,
    messageModalData,
    setMessageModalData,
    listingsModalData,
    setListingsModalData
  } = useContext(Context);
  const layout = useLayout();

  return (
    <StyledMainLayout>
      <StyledPeoplePage className="people">
        <div className="sticky-header">
          <PeopleControls />
          {layout !== 'mobile' && (
            <div className="list-header">
              <div className="list-header__item name">Name</div>
              <div className="list-header__item">Position</div>
              <div className="list-header__item">Active Transactions</div>
              <div className="list-header__item">Closed Transactions</div>
            </div>
          )}
        </div>

        {isLoading ? <TableSkeleton /> : <PeopleList />}
        <Modal
          modalTitle="Send Message"
          open={Boolean(messageModalData)}
          onClose={() => setMessageModalData(null)}>
          <SendMessage />
        </Modal>
        <SideMenu open={Boolean(listingsModalData)} onClose={() => setListingsModalData(null)}>
          {listingsModalData ? <RecentProjects data={listingsModalData} /> : null}
        </SideMenu>
      </StyledPeoplePage>
    </StyledMainLayout>
  );
};

export default props => (
  <ContextProvider>
    <PeoplePage {...props} />
  </ContextProvider>
);

const StyledPeoplePage = styled.div`
  position: relative;

  .people {
    &__title {
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      line-height: 32px;
      margin-right: auto;
    }
    &__description {
      font-size: 12px;
      line-height: 16px;
      color: ${props => props.theme.colors.grayDark};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding-top: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-wrap: wrap;
    .people {
      &__title {
        font-size: 20px;
        line-height: 30px;
        margin-right: auto;
        margin-bottom: 16px;
      }
      &__dropdown {
        margin-right: 12px;
      }
      &__description {
        width: 100%;
        margin: 0 0 20px;
      }
    }
  }

  .sticky-header {
    width: calc(100vw - 10px);
    background-color: #fff;
    z-index: 1000;
  }

  .list-header {
    display: flex;
    align-items: center;
    width: 100%;
    color: ${props => props.theme.colors.grayDark};
    &__item {
      padding: 12px 3px;
      font-size: 14px;
      line-height: 16px;
      flex: 1;
      &.name {
        flex: 2;
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    .sticky-header {
      width: 100%;
      position: sticky;
      top: -1px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .sticky-header {
      position: fixed;
      margin-top: -1px;
      width: calc(100vw);
      padding: 32px 20px 0 0;
    }
    .list-header {
      width: 675px;
    }
  }
`;

const StyledMainLayout = styled(MainLayout)`
  padding-bottom: 0;
  height: calc(100vh - 80px);
  .content {
    padding-top: 0;
  }
`;
