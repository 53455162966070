import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import Icon from 'components/Icon';

const Pill = ({ onCancelClick, children, ...props }) => {
  return (
    <StyledPill className={clsx('pill', { 'with-cancel-btn': onCancelClick })} {...props}>
      <div className="pill-content">{children}</div>
      {onCancelClick && (
        <button onClick={onCancelClick} className="cancel-btn">
          <Icon name="mini-x" size={10} />
        </button>
      )}
    </StyledPill>
  );
};

export default React.memo(Pill);

export const StyledPill = styled.span`
  position: relative;
  font-size: 16px;
  line-height: 24px;
  background-color: #f8f8f8;
  padding: 0 10px;
  margin: 0 5px 5px 0;
  max-width: 240px;
  display: inline-flex;
  align-items: center;

  .pill-content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.with-cancel-btn {
    padding-right: 0;
  }

  .cancel-btn {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;

    svg {
      fill: red;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 14px;
    .cancel-btn {
      svg {
        width: 8px;
        height: 8px;
      }
    }
  }
`;
