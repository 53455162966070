import React, { useContext, useMemo } from 'react';

import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import PeopleListItem from './PeopleListItem';
import { filterBySearchString, filterByRoles } from '../helpers';
import Context from '../context';

const PeopleList = () => {
  const { users, clientsData, positionFilter, positionOptions, search, openDetails } = useContext(
    Context
  );
  const layout = useLayout();

  const filteredUsers = useMemo(() => {
    let result = [...users];
    if (search) {
      result = filterBySearchString(result, search);
    }
    if (positionFilter.length && positionFilter.length !== positionOptions.length) {
      result = filterByRoles(result, positionFilter);
    }
    return result;
  }, [users, positionFilter, positionOptions, search]);

  return (
    <StyledPeopleList className="people-list">
      {layout === 'mobile' && (
        <div className="list-header">
          <div className="list-header__item name">Name</div>
          <div className="list-header__item">Position</div>
          <div className="list-header__item">Active Transactions</div>
          <div className="list-header__item">Closed Transactions</div>
        </div>
      )}
      {filteredUsers.length > 0 ? (
        filteredUsers.map((user: Client) => (
          <PeopleListItem
            data={clientsData[user.id] || user}
            key={user.id}
            onOpen={() => openDetails(user.id)}
          />
        ))
      ) : (
          <div className="empty-result">{search ? `${search} - not found...` : 'No result'}</div>
        )}
    </StyledPeopleList>
  );
};

export default React.memo(PeopleList);

const StyledPeopleList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  position: relative;

  .people-list {
    &__item {
      display: flex;
      flex-flow: wrap;
      padding: 16px 3px;
      border-top: 1px solid ${props => props.theme.colors.seashell};

      & > * {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &-info {
        flex: 1;
      }

      &:hover {
        background: ${props => props.theme.colors.seashell};
        cursor: pointer;
      }
    }
    &__item-name {
      flex: 2;
    }
  }

  .empty-result {
    margin-top: 16px;
    font-size: 16px;
    line-height: 32px;
    color: ${props => props.theme.colors.grayDark};
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 675px;
    padding: 154px 0 0 10px;
    .list-header {
      position: sticky;
      top: 154px;
      background: #fff;
      z-index: 1000;
    }
    .people-list__item:first-of-type {
      border-top: none;
    }
  }
`;
