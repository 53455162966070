export const filterBySearchString = (list = [] as Client[], searchString = '') => {
  if (!searchString) return list;

  const lowerCaseSearchString = searchString.toLowerCase();

  return list.filter(
    item =>
      item.name?.toLocaleLowerCase().includes(lowerCaseSearchString) ||
      item.email?.toLocaleLowerCase().includes(lowerCaseSearchString)
  );
};

export const filterByRoles = (list = [] as Client[], filter = [] as string[]) => {
  return list.filter(item => {
    const matches = item.position?.filter(role => filter.includes(role));
    return matches?.length;
  });
};

export const sortListByKeyName = (list = [], key, reverse = false) => {
  return list.sort((a, b) => {
    const left = reverse ? b[key] : a[key];
    const right = reverse ? a[key] : b[key];
    if (typeof left === 'string' && typeof right === 'string') {
      return left.toLowerCase() < right.toLowerCase() ? 1 : -1;
    }
    return left < right ? -1 : 1;
  });
};

export const setTableSortKeyAndDirection = (key, setTableSort, setTableSortDirection) => {
  // cycle sort: descending, ascending, no sort
  setTableSort(tableSort => {
    if (tableSort === key) {
      setTableSortDirection(sortDirection => {
        setTableSort(sortDirection !== 'asc' ? key : null);
        return sortDirection === 'desc' ? 'asc' : 'desc';
      });
      return;
    }
    setTableSortDirection('desc');
    return key;
  });
};

export const exportTableCSV = (name, tableData, tableColumns) => {
  const getCell = value => {
    if (value === null) value = 0;
    if (value === undefined) value = '';
    return `"${value}"`;
  };

  const csv = [
    tableColumns.map(item => item.text).join(','),
    ...tableData.map(item => tableColumns.map(column => getCell(item[column.key])).join(','))
  ]
    .join('\n')
    .replace('#', '#');

  const link = document.createElement('a');
  link.href = `data:text/csv;charset=utf-16,${encodeURIComponent(csv)}`;
  link.target = '_blank';
  link.download = `${name} [${new Date().toLocaleDateString()}].csv`;
  link.click();
};
