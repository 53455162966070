import React, { useContext } from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { camelToNormal } from 'utils/string';
import formatAddress from 'utils/formatAddress';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Context from '../context';

interface ClientDetailsProps {
  data: Client;
}

const ClientDetails: React.FC<ClientDetailsProps> = ({ data }) => {
  const { setMessageModalData, setListingsModalData } = useContext(Context);
  const { email, phone, listings, roles, officePhone, address } = data;

  const handleClickSendButton = () => {
    setMessageModalData({
      email: data.email,
      name: data.name
    });
  };

  const visibleListings = listings?.slice(0, 3) || [];
  const showProjects = listings?.length > 0;
  const getListingStatus = ({ status, mlsStatus }) => status || mlsStatus;

  return (
    <StyledClientDetails className="client-details">
      <div className="client-details__item client-details__header">
        <div className="client-details__item-info client-details__item__contact">Contact Info</div>
        {showProjects && (
          <>
            <div className="client-details__item-info client-details__item__project">
              Recent Projects
            </div>
            <div className="client-details__item-info client-details__item__type">Role</div>
            <div className="client-details__item-info client-details__item__status">
              Project status
            </div>
          </>
        )}
      </div>
      <div className="client-details__item">
        <div className="client-details__item-info client-details__item__contact">
          <a href={`mailto:${email}`} className="client-details__info client-details__link">
            <Icon name="email" />
            {email}
          </a>
          {phone && (
            <a href={`tel:${email}`} className="client-details__info client-details__link">
              <Icon name="smartphone" />
              {phone}
            </a>
          )}
          {officePhone && (
            <a href={`tel:${officePhone}`} className="client-details__info client-details__link">
              <Icon name="phone" />
              {officePhone}
            </a>
          )}
          {address && (
            <span className="client-details__info client-details__link">
              <Icon name="house" />
              {address}
            </span>
          )}
          <Button secondary onClick={handleClickSendButton} className="client-details__info">
            Send message
          </Button>
        </div>
        {showProjects && (
          <div className="client-details__item-info client-details__item__projects">
            {visibleListings?.map(listing => (
              <div key={listing.id} className="client-details__listing">
                <div className="client-details__item__project" key={listing.id}>
                  {formatAddress(listing as any)}
                </div>
                <div className="client-details__item__type">{camelToNormal(roles?.[listing.id])}</div>
                <div
                  className={clsx('client-details__item__status', {
                    active: getListingStatus(listing) === 'Active'
                  })}>
                  {getListingStatus(listing)}
                </div>
              </div>
            ))}
            <Button secondary onClick={() => setListingsModalData(data)} className="see-more-btn">
              See more
            </Button>
          </div>
        )}
      </div>
    </StyledClientDetails>
  );
};

export default React.memo(ClientDetails);

const StyledClientDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  /* padding: 8px 0 0 60px; */
  padding: 8px 0;
  &:hover {
    background: ${props => props.theme.colors.seashell};
  }

  .client-details {
    &__header {
      color: ${props => props.theme.colors.grayDark};
    }
    &__item {
      display: flex;
      width: 100%;
      padding: 6px 0;
    }
    &__item__contact {
      flex: 2;
    }
    &__item__project {
      flex: 1;
    }
    &__item__projects {
      flex: 3;
      .client-details__listing {
        display: flex;
        margin-bottom: 12px;

        & > div {
          padding-right: 12px;
        }
      }
    }
    &__item__type {
      flex: 1;
    }
    &__item__status {
      flex: 1;
      &.active {
        color: ${props => props.theme.colors.lightGreen};
      }
    }
    &__info {
      display: flex;
      align-items: center;
      & + .client-details__info {
        margin-top: 12px;
      }
    }
    &__link {
      color: inherit;
      text-decoration: none;
      .icon {
        margin-right: 12px;
      }
    }
    &__status {
      color: ${props => props.theme.colors.lightGreen};
    }
  }
  .see-more-btn {
    width: 75px;
  }
`;
