import React, { useState, useCallback, useEffect } from 'react';

import notify from 'notify';
import { camelToNormal } from 'utils/string';
import { DropdownOption } from 'components/Dropdown';
import { getRoles as getRolesRequest } from 'api/roles';
import { getPeople as getPeopleRequest, getClientListings, contactClient } from 'api/people';
import { filterBySearchString, filterByRoles, sortListByKeyName, exportTableCSV } from './helpers';

interface ContextValues {
  users: Array<Client>;
  clientsData: { [key: number]: Client; };
  messageModalData: Partial<Client> | null;
  setMessageModalData: React.Dispatch<React.SetStateAction<Partial<Client> | null>>;
  listingsModalData: Client | null;
  setListingsModalData: React.Dispatch<React.SetStateAction<Client | null>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  positionFilter: Array<string>;
  setPositionFilter: React.Dispatch<React.SetStateAction<Array<string>>>;
  positionOptions: Array<DropdownOption>;
  exportTable: () => void;
  isLoading: boolean;
  sendMessage: (values: { email: string; message: string; subject?: string; }) => Promise<void>;
  openDetails: (value: number) => void;
}

const tableColumns = [
  { key: 'name', text: 'Name' },
  { key: 'position', text: 'Position' },
  { key: 'transactionsOpen', text: 'Active Transactions' },
  { key: 'transactionsClosed', text: 'Closed Transactions' }
];

const Context = React.createContext({} as ContextValues);

export const ContextProvider = ({ children }) => {
  const [users, setUsers] = useState<Array<Client>>([]);
  const [messageModalData, setMessageModalData] = useState<Partial<Client> | null>(null);
  const [listingsModalData, setListingsModalData] = useState<Client | null>(null);
  const [clientsData, setClientsData] = useState<{ [key: number]: Client; }>({});
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [positionOptions, setPositionOptions] = useState<Array<DropdownOption>>([]);
  const [positionFilter, setPositionFilter] = useState<Array<string>>([]);

  const getPeople = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getPeopleRequest();
      setUsers(data);
    } catch (err) {
      notify(err.message);
    }
    setIsLoading(false);
  }, [getPeopleRequest]);

  const getRoles = useCallback(async () => {
    try {
      const data = await getRolesRequest();
      const positionOptions = data.map(item => ({
        value: item.role,
        text: camelToNormal(item.role)
      }));
      setPositionOptions(positionOptions);
      setPositionFilter(data.map(item => item.role));
    } catch (err) {
      notify(err.message);
    }
  }, [getRolesRequest]);

  useEffect(() => {
    getPeople();
    getRoles();
  }, []);

  const openDetails = async id => {
    const user = users.find(user => user.id === id);
    const clientData: Client | null = clientsData[id];

    if (!clientData) {
      try {
        const data = await getClientListings(id);
        setClientsData(v => ({ ...v, [id]: { ...user, listings: data } }));
      } catch (err) {
        const errText = err.response ? err.response.data.message : err.message;
        notify(errText);
      }
    }
  };

  const sendMessage = async (values: { email: string; message: string; subject?: string; }) => {
    try {
      await contactClient(values);
      notify('Email was successfully sent');
      setMessageModalData(null);
    } catch (err) {
      notify(err.message);
    }
  };

  const getTableData = useCallback(
    (tableSort?: string, tableSortDirection?: string) => {
      let list = Array.from(users);

      if (search) {
        list = filterBySearchString(list, search);
      }
      if (tableSort) {
        list = sortListByKeyName(list, tableSort, tableSortDirection !== 'asc');
      }
      if (positionFilter.length && positionFilter.length !== positionOptions.length) {
        list = filterByRoles(list, positionFilter);
      }
      return list;
    },
    [positionOptions.length, positionFilter, search, users]
  );

  const exportTable = () => exportTableCSV('people', getTableData(), tableColumns);

  const values: ContextValues = {
    users,
    clientsData,
    messageModalData,
    setMessageModalData,
    listingsModalData,
    setListingsModalData,
    search,
    setSearch,
    positionFilter,
    setPositionFilter,
    positionOptions,
    exportTable,
    isLoading,
    sendMessage,
    openDetails
  };

  return <Context.Provider value={values}>{children}</Context.Provider>;
};

export default Context;
