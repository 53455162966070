/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import styled from '@emotion/styled';
import Button from 'components/Button';
import Input from 'components/Input';
import Pill from 'components/Pill';
import { REGEXP } from 'consts';
import Context from '../context';

const getContact = (users: Client[], email: string) => {
  const existingUser = users.find(user => user.email === email);
  const name = existingUser && existingUser.name ? existingUser.name : '';
  return { name, email };
};

const SendMessage = () => {
  const { messageModalData, users, sendMessage } = useContext(Context);
  const [contacts, setContacts] = useState<Array<Partial<Client>>>(
    messageModalData?.email ? [getContact(users, messageModalData.email)] : []
  );
  const {
    register,
    setValue,
    formState: { errors, isSubmitting },
    clearErrors,
    setError,
    trigger,
    getValues,
    handleSubmit
  } = useForm({
    defaultValues: {
      email: '',
      subject: '',
      message: ''
    }
  });

  const contactEmails = useMemo(() => contacts?.map(contact => contact.email), [contacts]);

  const handleAddEmail = async () => {
    const email = getValues('email');
    const isValid = REGEXP.EMAIL.test(email);

    if (isValid) {
      addEmail(email);
      clearErrors();
      setValue('email', '');
    } else {
      setError('email', { message: 'Invalid Email' });
    }
  };

  const addEmail = email => {
    const contact = getContact(users, email);
    setContacts(v => [...v, contact]);
  };

  const deleteEmail = email => {
    setContacts(v => v.filter(contact => contact.email !== email));
  };

  const onSubmit = async () => {
    const { subject, message } = getValues();
    if (message && contactEmails.length) {
      await sendMessage({ subject, message, email: contactEmails.join(',') });
    } else {
      trigger(['email', 'message']);
    }
  };

  const renderPills = () =>
    contacts.length > 0 && (
      <div className="addressee">
        <div className="pill-container">
          {contacts.map((contact, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Pill key={idx} onCancelClick={() => deleteEmail(contact.email)}>
              {contact.name || contact.email}
            </Pill>
          ))}
        </div>
      </div>
    );

  return (
    <StyledSendMessage className="message-box">
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="email">To</label>
        <div className="add-email-control">
          <Input
            {...register('email', {
              pattern: { value: REGEXP.EMAIL, message: 'Invalid email' },
              validate: () => contactEmails.length > 0 || 'At least one email is required'
            })}
            id="email"
            error={errors.email?.message}
            className="email-field"
            placeholder="Enter"
            // onBlur={handleAddEmail}
            data-cy="input_email"
          />
          <Button
            simple
            type="button"
            className="add-email-addon"
            onClick={handleAddEmail}
            data-cy="button_add_email">
            + Add
          </Button>
        </div>
        {renderPills()}
        <label htmlFor="subject">Subject</label>
        <Input
          {...register('subject')}
          id="subject"
          className="title-field"
          placeholder="Enter"
          data-cy="input_subject"
        />
        <label htmlFor="message">Message</label>
        <Input
          {...register('message', { required: 'Required' })}
          id="message"
          as="textarea"
          error={errors.message?.message}
          className="message-field"
          placeholder="Write message..."
          data-cy="input_textarea_message"
        />
        <div className="button-container">
          <Button className="btn-send" disabled={isSubmitting} data-cy="button_submit">
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </Button>
        </div>
      </form>
    </StyledSendMessage>
  );
};

export default React.memo(SendMessage);

export const StyledSendMessage = styled.div`
  width: 324px;
  max-width: 100%;

  .input {
    display: block;
  }

  .add-email-control {
    position: relative;

    input {
      padding-right: 60px !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .add-email-addon {
      display: inline-flex;
      padding: 14px 8px;
      height: 40px;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
      font-weight: bold;
      color: ${props => props.theme.colors.red};
      text-transform: uppercase;
      cursor: pointer;
      text-overflow: ellipsis;

      .icon {
        width: 10px;
        height: 10px;
        margin-right: 7px;
        fill: ${props => props.theme.colors.red};
      }
    }
  }

  .addressee {
    margin: -8px 0 8px;
    display: flex;

    .pill-container {
      margin: 0;
      & > span {
        display: inline-flex;
        padding: 4px 2px 4px 4px;
        font-size: 12px;
        line-height: 16px;
        background: white;
        border: 1px solid black;
        border-radius: 3px;
      }

      .cancel-btn {
        margin-left: 2px;
      }
      .cancel-btn .icon {
        width: 8px;
        height: 8px;
      }
    }

    .pill-label {
      line-height: 26px;
      height: 26px;
    }
  }

  .input {
    margin-bottom: 16px;
  }

  .message-field {
    height: auto;
  }

  textarea {
    box-sizing: border-box;
    width: 100%;
    height: 160px;
    padding: 12px 8px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid ${props => props.theme.colors.seashell};
    border-radius: ${props => props.theme.misc.borderRadius};
    resize: none;
    outline: none;

    &:hover,
    &:focus {
      border-color: black;
    }
  }

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 10px;
    line-height: 16px;
    color: ${props => props.theme.colors.grayDark};
  }

  .button-container {
    margin-top: 16px;
    .btn-send {
      width: 100%;
      height: 40px;
      padding: 12px 8px;
      border-radius: 3px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;
